$fontFamily: 'Open Sans', sans-serif;

$colorAccent: #e52b3e;
$colorBackground: #0c0c0c;

$layoutWidth: 1200px;
$layoutPadding: 20px;
$layoutNavigationHeight: 50px;

@mixin layoutBox() {
    max-width: $layoutWidth;
    padding: 0 $layoutPadding;
    margin: 0 auto;
    width: 100%;
}

@mixin boxStyle {
    text-decoration: none;
    box-shadow: 0 0 10px rgba(black, .25);
    border: none;
    border-radius: 10px;
    background: none;
    padding: $layoutPadding;
    font: inherit;
    color: inherit;
    margin: $layoutPadding 0;

    @media(prefers-color-scheme: dark) {
        background: rgba(255, 255, 255, .05);
    }
}

@mixin button {
    @include boxStyle;
    background: rgba(white, .75);
    color: $colorAccent;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    font: inherit;
    transition: all .2s ease-in-out;

    @media(prefers-color-scheme: dark) {
        color: white;
    }

    &:hover {
        background: white;
        color: $colorAccent;
    }
}

@mixin formStyles() {
    button {
        @include button;
    }

    input[type=text], input[type=password], textarea, input[type=number] {
        @include boxStyle;
            
        display: block;

        &.center {
            margin: $layoutPadding auto;
        }
    }

    label {
        display: block;
        
        margin-top: $layoutPadding;
        margin-bottom: (-$layoutPadding + 5px);

        &.center {
            text-align: center;
        }
    }

    textarea {
        width: 100%;
        display: block;
    }
}

.layoutBox {
    @include layoutBox();
    @include formStyles();
}

.title {
    display: block;
    font-weight: 800;
    font-size: 3em;
    padding: 20px 0;
}

.flexList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    > * {
        margin: $layoutPadding;
    }
}