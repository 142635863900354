@import "../common";

.heroBox {
    margin-top: -$layoutNavigationHeight;
    
    > div {
        padding-top: 100px;
        padding-bottom: 100px;
        min-height: 300px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {

            text-align: center;

            &.heroPretitle {
                font-size: 2em;
                font-weight: 100;
                margin-bottom: -20px;
            }

            &.heroTitle {
                font-size: 4em;
                font-weight: 800;
            }

            &.heroSubtitle {
                font-size: 1.5em;
                font-weight: 400;
                margin-bottom: -20px;
            }
        }
    }
}